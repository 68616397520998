import {
  ParsedAssetBrowserSelectionState,
  VideoFeatureAppConfig,
  VideoFeatureAppVideo,
  VideoProps,
  VideosProps,
} from '../components/interfaces';

/**
 * Transforms the json editor response for video into the config of the video feature app.
 * Returns not exported video interface (see: https://github.com/volkswagen-onehub/audi-feature-app-video#usage)
 *
 * @example
 * in:
 * ```typescript
 * const jsonEditorContent = {
 *   videoNarrowScreen: {
 *     assetDisclaimer: 'Disclaimer',
 *     autoplay: false,
 *     loop: false,
 *     mute: false,
 *     poster: {
 *       assetValue: 'https://www.audi.de/content/dam/nemo/teaser-new-home-full-width/2020/models/q8-tfsi-e/1920x1920_Q8_2020_1443.jpg',
 *     },
 *     video: {
 *       assetValue: 'http://localhost:3000/demo/data/audi-commercial-mobile.mp4',
 *     },
 *     videoAlt: 'Audi commercial narrow',
 *   },
 *   videoWideScreen: {
 *     assetDisclaimer: 'Disclaimer',
 *     autoplay: false,
 *     loop: false,
 *     mute: false,
 *     poster: {
 *       assetValue: '{"image":[{"fileInfo":{"fileSource":"https://www.audi.de/content/dam/nemo/teaser-new-home-full-width/2020/models/q8-tfsi-e/1920x1920_Q8_2020_1443.jpg"}}]}',
 *     },
 *     video: {
 *       assetValue: '{"video":[{"sourceFormats":{"content_1080p":"http://localhost:3000/demo/data/audi-commercial-mobile.mp4"}}]}',
 *     },
 *     videoAlt: 'Audi commercial wide',
 *   },
 * };
 * ```
 *
 * out:
 * ```typescript
 * const featureAppConfig = {
 *   cover: true,
 *   playsInline: true,
 *   video: {
 *     m: {
 *       alt: 'Audi commercial wide',
 *       aspectRatio: '16/9',
 *       autoPlay: false,
 *       loop: false,
 *       muted: false,
 *       poster:
 *         'https://www.audi.de/content/dam/nemo/teaser-new-home-full-width/2020/models/q8-tfsi-e/1920x1920_Q8_2020_1443.jpg',
 *       src: 'http://localhost:3000/demo/data/audi-commercial-mobile.mp4',
 *     },
 *     xs: {
 *       alt: 'Audi commercial narrow',
 *       aspectRatio: '3/4',
 *       autoPlay: false,
 *       loop: false,
 *       muted: false,
 *       poster:
 *         'https://www.audi.de/content/dam/nemo/teaser-new-home-full-width/2020/models/q8-tfsi-e/1920x1920_Q8_2020_1443.jpg',
 *       src: 'http://localhost:3000/demo/data/audi-commercial-mobile.mp4',
 *     },
 *   },
 * };
 * ```
 */
export const parseVideoAppConfig = (videos?: VideosProps): VideoFeatureAppConfig | null => {
  if (!videos) {
    return null;
  }

  return {
    cover: true,
    playsInline: true,
    videos: {
      m: parseAssetBrowserVideo(videos.videoWideScreen, '16/9', 'content_1080p'),
      xs: parseAssetBrowserVideo(videos.videoNarrowScreen, '3/4', 'content_720p'),
    },
  };
};

const parseAssetBrowserVideo = (
  assetBrowserVideo: VideoProps,
  aspectRatio: string,
  sourceFormat: string
): VideoFeatureAppVideo => {
  const {
    videoAlt,
    poster: assetPoster,
    video: assetVideo,
    autoplay: autoPlay,
    loop,
    mute: muted,
  } = assetBrowserVideo;

  let video = assetVideo.assetValue;
  let poster = assetPoster?.assetValue;

  try {
    video = JSON.parse(video);
    poster = JSON.parse(poster as string);
  } catch (error) {
    // keep silent, because both `assetVideo` and `assetPoster` may be a URL string which is not parsable.
  }

  const result = {
    alt: videoAlt,
    aspectRatio,
    autoPlay,
    loop,
    muted,
    src:
      // Asset browser URL (string) or object
      typeof video === 'string'
        ? video
        : (video as ParsedAssetBrowserSelectionState).video[0].sourceFormats[sourceFormat],
  };

  if (poster) {
    return {
      ...result,
      poster:
        // Asset browser URL (string) or object
        typeof poster === 'string'
          ? poster
          : (poster as ParsedAssetBrowserSelectionState).image[0].fileInfo.fileSource,
    };
  }

  return result;
};
