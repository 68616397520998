import { AssetBrowserProps, ParsedAssetBrowserSelectionState } from '../components/interfaces';

export const parseImageAsset = (assetImage: AssetBrowserProps): string | null => {
  if (!assetImage) {
    return null;
  }

  let image = assetImage.assetValue;

  try {
    image = JSON.parse(image);
    return (image as unknown as ParsedAssetBrowserSelectionState).image[0].fileInfo.fileSource;
  } catch (error) {
    // keep silent, because `image` may be a URL string which is not parsable.
    return image;
  }
};
