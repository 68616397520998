import * as React from 'react';
import { Text, ThemeProvider, audiLightTheme } from '@audi/audi-ui-react-v2';
import { useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import { AudiStageLargeInfoContentProps, ContainerProps, HeadlineWrapperProps } from './interfaces';
import AudiStageLargeButtonWrapper from './audi-stage-large-button-wrapper';
import FootnoteReplacer from './audi-stage-large-footnote-replacer';

const { l: breakpointL, m: breakpointM } = audiLightTheme.breakpoints;

const ContentWrap = styled.div`
  height: 100%;
  margin: 0 4%;
  max-width: 1440px;
  pointer-events: none;
  position: relative;
  width: 92%; /* x = 100/92*1440 */
  @media (min-width: 1566px) {
    margin: 0 calc(calc(100% - 1440px) / 2);
  }
  z-index: 4;
`;

const Container = styled.div<ContainerProps>`
  @media (max-width: ${breakpointM - 1}px) {
    ${({ alignTop, isVideo }): string =>
      !alignTop || isVideo ? 'bottom: 0; height: auto' : 'top: 0; height: 100%'};
  }
  @media (min-width: ${breakpointM}px) {
    ${({ alignTop, isVideo }): string =>
      alignTop || isVideo ? 'top: 0; height: 100%' : 'bottom: 0; height: auto'};
  }
  position: absolute;
  width: 100%;
`;

const HeadlineContainer = styled.div`
  @media (min-width: ${breakpointL} px) {
    width: 72%;
  }
`;

const SublineContainer = styled.div`
  @media (min-width: ${breakpointL} px) {
    width: 44%;
  }
`;

const HeadlineSublineWrapper = styled.div<HeadlineWrapperProps>`
  ${({ alignRight }): string =>
    alignRight ? 'text-align: right; margin-right: 0; margin-left: auto;' : ''};
  pointer-events: all;
  width: 92%;
  @media (max-width: ${breakpointM - 1}px) {
    ${({ isVideo }): string => (isVideo ? 'width: 84%;' : 'width: 92%;')};
  }
`;

const AudiStageLargeInfoContent = React.forwardRef<
  {
    readonly wrapper: HTMLDivElement;
    readonly button: HTMLDivElement;
  },
  AudiStageLargeInfoContentProps
>((props, ref) => {
  const {
    textElements,
    alignRight,
    alignTop,
    theme,
    footnoteReferences,
    referenceServiceManager,
    isVideo,
  } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => ({
    get button(): HTMLDivElement {
      return buttonRef.current;
    },
    get wrapper(): HTMLDivElement {
      return wrapperRef.current;
    },
  }));
  return (
    <ThemeProvider theme={theme}>
      <ContentWrap>
        <Container {...{ alignTop, isVideo }} ref={wrapperRef}>
          <HeadlineSublineWrapper {...{ alignRight, isVideo }}>
            {textElements.headline && textElements.headline.headlineText && (
              <HeadlineContainer>
                {textElements.headline.headlineHtmlTag && (
                  <Text
                    as={textElements.headline.headlineHtmlTag}
                    spaceStackEnd="xs"
                    spaceStackStart="xl"
                    variant="order1"
                  >
                    <FootnoteReplacer
                      content={textElements.headline.headlineText}
                      footnoteReferences={footnoteReferences}
                      referenceServiceManager={referenceServiceManager}
                    />
                  </Text>
                )}
              </HeadlineContainer>
            )}
            <SublineContainer>
              {textElements.subline && textElements.subline.sublineText && (
                <Text as="h4" spaceStackEnd="l" variant="order4">
                  <FootnoteReplacer
                    content={textElements.subline.sublineText}
                    footnoteReferences={footnoteReferences}
                    referenceServiceManager={referenceServiceManager}
                  />
                </Text>
              )}
            </SublineContainer>
          </HeadlineSublineWrapper>
          {(textElements.primaryButton || textElements.secondaryButton) && (
            <AudiStageLargeButtonWrapper
              alignRight={alignRight}
              alignTop={isVideo ? false : alignTop}
              primaryButton={textElements.primaryButton}
              ref={buttonRef}
              secondaryButton={textElements.secondaryButton}
            />
          )}
        </Container>
      </ContentWrap>
    </ThemeProvider>
  );
});

AudiStageLargeInfoContent.displayName = 'AudiStageLargeInfoContent';

export default AudiStageLargeInfoContent;
