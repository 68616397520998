import * as React from 'react';
import { audiLightTheme } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { AudiStageLargeImageProps } from './interfaces';

const Image = styled.img`
  height: 100%;
  object-fit: cover;
  position: relative;
  width: 100%;
`;

const MediaItem = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`;

const {
  l: breakpointL,
  m: breakpointM,
  xl: breakpointXL,
  xxl: breakpointXXL,
} = audiLightTheme.breakpoints;

const AudiStageLargeImage: React.FC<AudiStageLargeImageProps> = (props) => {
  const { imageAlt, imageNarrow, imageWide } = props;

  return (
    <MediaItem>
      <picture>
        {/* < 768 */}
        <source
          media={`(max-width: ${breakpointM}px) and (max-aspect-ratio: 3/4)`}
          srcSet={`${imageNarrow}?imwidth=${breakpointM}`}
        />
        <source
          media={`(max-width: ${breakpointM}px) and (min-aspect-ratio: 3/4)`}
          srcSet={`${imageWide}?imwidth=${breakpointM}`}
        />
        {/* < 1024 */}
        <source
          media={`(max-width: ${breakpointL}px) and (max-aspect-ratio: 3/4)`}
          srcSet={`${imageNarrow}?imwidth=${breakpointL}`}
        />
        <source
          media={`(max-width: ${breakpointL}px) and (min-aspect-ratio: 3/4)`}
          srcSet={`${imageWide}?imwidth=${breakpointL}`}
        />
        {/* < 1440 */}
        <source
          media={`(max-width: ${breakpointXL}px) and (max-aspect-ratio: 3/4)`}
          srcSet={`${imageNarrow}?imwidth=${breakpointXL}`}
        />
        <source
          media={`(max-width: ${breakpointXL}px) and (min-aspect-ratio: 3/4)`}
          srcSet={`${imageWide}?imwidth=${breakpointXL}`}
        />
        {/* >= 1440 */}
        <source
          media={`(min-width: ${breakpointXL}px) and (max-aspect-ratio: 3/4)`}
          srcSet={`${imageNarrow}?imwidth=${breakpointXXL}`}
        />
        <source
          media={`(min-width: ${breakpointXL}px) and (min-aspect-ratio: 3/4)`}
          srcSet={`${imageWide}?imwidth=${breakpointXXL}`}
        />
        {/* fallback */}
        <Image alt={imageAlt} data-object-fit src={`${imageWide}?imwidth=${breakpointM}`} />
      </picture>
    </MediaItem>
  );
};

export default AudiStageLargeImage;
