import * as React from 'react';

import { Button, SpacingLabel, audiLightTheme } from '@audi/audi-ui-react';

import styled from 'styled-components';
import { AudiStageLargeButtonWrapProps, ButtonContainerProps } from './interfaces';
import { useWindowSize } from './hooks';

const ButtonContainer = styled.div<ButtonContainerProps>`
  pointer-events: all;
  @media (min-width: ${({ breakpointM }): number => breakpointM}px) {
    align-items: flex-start;
    display: flex;
    flex-flow: row;
    justify-content: ${({ alignRight }): string => (alignRight ? 'flex-end' : 'flex-start')};
    max-width: initial;
  }
  @media (max-width: ${({ breakpointM }): number => breakpointM - 1}px) {
    ${({ alignTop }): string => (alignTop ? 'position: absolute; bottom: 0;' : '')}
    width: 100%;

    & a {
      width: 100%;
    }
  }
`;

const AudiStageLargeButtonWrapper = React.forwardRef<HTMLDivElement, AudiStageLargeButtonWrapProps>(
  (props, forwarededButtonWrapperRef) => {
    const { alignRight, alignTop, primaryButton, secondaryButton } = props;

    const { width } = useWindowSize();

    const { m: breakpointM } = audiLightTheme.breakpoints;
    let primaryButtonMargin: SpacingLabel = 'xl';
    if (width < breakpointM && secondaryButton) {
      primaryButtonMargin = 'xxs';
    }

    return (
      <ButtonContainer
        alignRight={alignRight}
        alignTop={alignTop}
        breakpointM={breakpointM}
        ref={forwarededButtonWrapperRef}
      >
        {primaryButton && (
          <Button
            href={primaryButton.linkUrl}
            newWindow={primaryButton.linkTarget === '_blank'}
            spaceInlineEnd="xxs"
            spaceStackEnd={primaryButtonMargin}
            variant="primary"
          >
            {primaryButton.linkText}
          </Button>
        )}
        {primaryButton && secondaryButton && (
          <Button
            href={secondaryButton.linkUrl}
            newWindow={secondaryButton.linkTarget === '_blank'}
            spaceStackEnd="xl"
            variant="secondary"
          >
            {secondaryButton.linkText}
          </Button>
        )}
      </ButtonContainer>
    );
  }
);

AudiStageLargeButtonWrapper.displayName = 'AudiStageLargeButtonWrapper';

export default AudiStageLargeButtonWrapper;
